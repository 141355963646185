<template>
  <tr class="measurementmain" v-on:click="toggleopen()">
  <td v-if="header == 'no'" class="id">{{measurement.installationId}}</td>
  <td v-if="header == 'no'" class="country">{{installation.country}}</td>
  <td v-if="header == 'no'" class="city">{{installation.city}}</td>
  <td v-if="header == 'no'" class="name">{{installation.instname}}</td>
  <td v-if="header == 'no'" class="queue">{{queue}}</td>
  <td v-if="header == 'no'" class="reason">{{measurement.recorderInfo.reason}}</td>
  <td v-if="header == 'no'" class="date">{{measurement.isoDateUTC}}</td>
  <td v-if="header == 'no'" class="isref">{{
            this.autoDeleteTimestamp != 0 ? '\u{1F5D1}' :
            measurement.analysis == null ? '\u26A1' :
            measurement.isReference ? '\u2705' : '\u2716'}}</td>

  <th v-if="header == 'yes'" class="id">Inst</th>
  <th v-if="header == 'yes'" class="country">Country</th>
  <th v-if="header == 'yes'" class="city">City</th>
  <th v-if="header == 'yes'" class="name">Installation</th>
  <th v-if="header == 'yes'" class="queue">Queue</th>
  <th v-if="header == 'yes'" class="reason">Reason</th>
  <th v-if="header == 'yes'" class="date">Date</th>
  <th v-if="header == 'yes'" class="isref">Ref</th>
  </tr>

  <tr class="measurementdetail" v-if="opened  == true">
    <td colspan=3>
      <a :href="pdfUrl" target="_blank" title="Download PDF"><img class="link" src="../assets/pdf.png"></a>
      <a :href="smallZipUrl" title="Download important data"><img class="link" src="../assets/disk.png"></a>
      <a :href="bigZipUrl" title="Download all data"><img class="link" src="../assets/diskwarn.png"></a>

    </td>
    <td>
      <span><img class="link" title="Reanalyze" src="../assets/refresh.png" v-on:click="reanalyze()"></span>
      <div id="reanamessage" class="error">{{this.reanaMessage}}</div>
    </td>
    <td colspan=4>
      Move to:
      <select class="input" v-model="this.queue" v-on:change="queuechanged">
        <option v-for="option of queues" :key="option">{{option}}</option>
      </select>
      <span class="error">{{this.moveError}}</span>
    </td>
  </tr>
  <tr class="measurementdetail" v-if="opened  == true">
    <td v-if="measurement.analysis" colspan=3>Distancesensors: {{measurement.analysis.distancesensorsState}} min {{measurement.analysis.minDistance}} max {{measurement.analysis.maxDistance}}</td>
    <td v-if="!measurement.analysis" colspan=3>No Analysis found</td>
    <td :class="this.autoDeleteClass(this.autoDeleteTimestamp)" v-on:click="stopautodelete()">{{this.formatDeleteMessage(this.autoDeleteTimestamp)}}</td>
    <td colspan=4>
        Control program: {{measurement.recorderInfo.generator}}
        <div v-if="measurement.uploadTimeStart > 0">
            Upload: {{Math.floor(measurement.totalFileSize/1000)/1000}} MB in {{Math.floor((measurement.uploadTimeEnd - measurement.uploadTimeStart)/100)/10}} sec, 
            {{Math.floor((measurement.totalFileSize/1000) / (measurement.uploadTimeEnd - measurement.uploadTimeStart))}} MB/sec
        </div>
    </td>
  </tr>
  <tr class="measurementspacer" v-if="opened  == true">
  </tr>
</template>

<script>
import { API } from '../API';

export default {
  name: 'Installation',
  props: {
    measurement: Object,
    installation: Object,
    header: String,
    queues: Array
  },
  data() {
    return {
      opened: false,
      pdfUrl : "",
      smallZipUrl: "",
      bigZipUrl: "",
      originalQueue: "",
      queue: "",
      moveError: "",
      reanaMessage: "",
      autoDeleteTimestamp: 0,
    }
  },
  methods: {
    toggleopen() {
      if (this.header == "no") {
        this.opened = !this.opened;
      }
    },
    queuechanged() {
      this.moveError = "";
      API.transact("/measurements/move/"+this.measurement.uniqueID+"/"+this.queue, this.queuechangedApiResponded);
    },
    queuechangedApiResponded(status, apiresult) {
      if (status == false) {
        this.moveError = "Move to " + this.queue + ": " + apiresult.error;
        this.queue = this.originalQueue;
        console.log(apiresult);
      } else {
        this.originalQueue = this.queue;
      }
    },
    
    reanalyze() {
      if (this.reanaMessage == "") {
        API.transact("/measurements/reanalyze/"+this.measurement.uniqueID, this.reanalyzeApiResponded);
      }
    },
    reanalyzeApiResponded(status, apiresult) {
      if (status == true) {
        this.reanaMessage = apiresult.result;
      } else {
        this.reanaMessage = apiresult.error;
      }
      console.log(status, apiresult);
    },

    stopautodelete() {
      if (this.autoDeleteTimestamp > 0) {
        API.transact("/measurements/preserve/"+this.measurement.uniqueID, this.preserveApiResponded);
      }
    },
    preserveApiResponded(status) {
      if (status == true) {
        this.autoDeleteTimestamp = 0;
      }
    },

    formatDeleteMessage(timestamp) {
      if (timestamp == 0) {
        return "No autodelete";
      }
      var d = new Date(timestamp);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;

      return "Autodelete: " + [year, month, day].join('-');      
    },

    autoDeleteClass(timestamp) {
      return (timestamp == 0 ? "" : "action");
    },

  },
  mounted() {
    if (this.measurement) {   // this is false when we're doing the header
//      var urlpath = this.measurement.installationId + "/" + this.measurement.ropeId + "/" + this.measurement.YYYYMMDDhhmmss ;
      var urlpath = this.measurement.uniqueID;
      if (this.measurement.qualifier != "") {
        urlpath += '-' + this.measurement.qualifier;
      }
      this.pdfUrl = window.getApiUrl() + "downloadpdf/" + urlpath;
      this.smallZipUrl = window.getApiUrl() + "downloadzip/" + urlpath;
      this.bigZipUrl = window.getApiUrl() + "downloadbigzip/" + urlpath;
      this.queue = this.originalQueue = this.measurement.queue;
      this.autoDeleteTimestamp = this.measurement.autoDeleteTimestamp;
    }
  },
}
</script>

<style scoped>
th,td {
  text-align: left;
  font-size: 15px;
  color:black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tr.measurementmain {
  height: 30px;
}

tr.measurementdetail {
  height: 60px;
  background-color: azure;
}

tr.measurementdetail td {
  border-radius: 10px;
  border: 1px solid black;
  padding: 5px;
}

tr.measurementspacer {
  height: 20px;
}

/* select box */
.input {
  height: 20px;
  font-size: 16px;
}

#reanamessage {
  width: 130px;
  display: inline-block;
  white-space: break-spaces;
}

.id { width: 100px; }
.country { width: 100px; }
.city { width: 150px; }
.name { width: 200px; }
.queue { width: 100px;}
.reason { width: 100px;}
.date { width: 150px;}
.isref { width: 50px; }

.link { margin: 5px; }

.error { margin-left: 10px; color: red; }

.action { cursor: pointer; }

</style>
